import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const createTeamsSelector = () => createSelector(
  ({ teams }) => teams,
  teams => teams,
);

export const useTeams = () => {
  const selector = useMemo(createTeamsSelector, []);
  return useSelector(selector);
};
