import { CLEAR, SET_TEAMS, SET_QUERY } from './TeamsReducer.js';
import ApiService from '../../services/ApiService.js';

export const setQuery = query => ({
  type: SET_QUERY,
  query,
});

const setTeams = teams => ({
  type: SET_TEAMS,
  teams,
});

export const clear = () => ({
  type: CLEAR,
});

export const searchForTeams = query => async dispatch => {
  const { results = [] } = await ApiService.getInstance().searchForTeams(query);
  dispatch(setTeams(results));
};
