import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import Layout from '../../components/Layout.js';
import TallContainer from '../../components/TallContainer.js';
import SearchForm from '../../components/forms/SearchForm.js';
import SearchResult from '../../components/search/SearchResult.js';
import SearchResults from '../../components/search/SearchResults.js';
import { clear, searchForTeams, setQuery } from '../../stores/Teams/TeamsActions.js';
import { useTeams } from '../../stores/Teams/TeamsSelectors.js';
import { useSportById } from '../../stores/Sports/SportsSelectors.js';
import SearchResultsDelegate from '../../components/search/SearchResultsDelegate.js';

const TeamSearchForm = () => {
  const dispatch = useDispatch();
  const { query } = useTeams();

  const debouncedSearch = useCallback(debounce(q => dispatch(searchForTeams(q)).then(), 300), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = q => {
    dispatch(setQuery(q));

    if (!q || !q.length) {
      dispatch(clear());
    } else {
      debouncedSearch(q);
    }
  };

  return (
    <SearchForm
      placeholder="Search for a team..."
      onChange={onChange}
      value={query}
    />
  );
};

const TeamSearchResult = (
  {
    result: {
      team: { id: teamId, name: teamName } = {},
      area: { name: areaName } = {},
      sport: { id: sportId } = {},
    },
  },
) => {
  const sport = useSportById(sportId);

  return (
    <SearchResult
      sport={sport}
      href={`/banners/sports/${sport.id}/teams/${teamId}`}
    >
      {teamName}
      <span className="search-results__small-text">
        {areaName ? `(${areaName})` : `(World)`}
      </span>
    </SearchResult>
  );
};

const TeamSearchResults = () => {
  const { teams } = useTeams();

  return (
    <SearchResults>
      {teams.map(result => (
        <TeamSearchResult key={`comp-${result.team?.id}`} result={result} />
      ))}
    </SearchResults>
  );
};

const TeamSearch = () => {
  const { query, status, teams = [] } = useTeams();

  return (
    <TallContainer>
      <TeamSearchForm />
      <SearchResultsDelegate
        query={query}
        status={status}
        hasResults={teams.length}
        ResultComponent={TeamSearchResults}
      />
    </TallContainer>
  );
};

const TeamBannersPage = () => {
  return (
    <Layout title={{ black: 'Configure', blue: 'Team Banners' }}>
      <TeamSearch />
    </Layout>
  );
};

export default TeamBannersPage;
